// We're using this in the prerender-urls.js file in node.js modules land
// fortunately webpack will deal with the require but node.js modules will not
// import md5 from 'md5'
const md5 = require('md5')

class Imgix {
  constructor (uri, token) {
    this.uri = uri
    this.token = token
  }

  sign (uriEncodedPath, queryString) {
    // https://github.com/imgix/imgix-blueprint#securing-urls
    const signature = md5(`${this.token}${uriEncodedPath}${queryString || ''}`)
    return `${uriEncodedPath}${queryString || ''}${queryString ? '&' : '?'}s=${signature}`
  }

  buildSrc (imgUri, params) {
    const pairs = []
    params.auto = 'compress,enhance,format'
    for (const key in params) {
      pairs.push(`${key}=${params[key]}`)
    }
    const queryString = `?${pairs.join('&')}`
    const uriEncodedPath = `/${encodeURIComponent(imgUri)}`
    return `${this.uri}${this.sign(uriEncodedPath, queryString)}`
  }
}

module.exports = Imgix
