/* global API_KEY, API_URI, JANIIS_API_URI */
import { formatDateForSearch } from './utils'

// API_KEY and API_URI are provided at build-time. see preact.config.js
const apiKey = API_KEY
const apiUri = API_URI
const janiisApiUri = JANIIS_API_URI

const headers = {
  Accepts: 'application/json',
  'Content-Type': 'application/json',
  'X-Api-Key': apiKey
}

function prepareParamsForSearch (checkInDate, checkOutDate, guests, discountCode) {
  const params = {}

  if (checkInDate) {
    params['check-in'] = formatDateForSearch(checkInDate)
  }

  if (checkOutDate) {
    params['check-out'] = formatDateForSearch(checkOutDate)
  }

  if (guests) {
    params.guests = guests
  }

  if (discountCode) {
    params['discount-code'] = discountCode
  }

  return new URLSearchParams(params).toString()
}

async function getSearchResults (checkInDate, checkOutDate, guests) {
  const url = new URL(`${apiUri}/search`)
  url.search = prepareParamsForSearch(checkInDate, checkOutDate, guests)

  console.info(`Fetching search results from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers })
  return response.json()
}

async function getUnitsForProperty (propertyId, checkInDate, checkOutDate, guests) {
  const url = new URL(`${apiUri}/properties/${propertyId}/units`)
  url.search = prepareParamsForSearch(checkInDate, checkOutDate, guests)

  console.info(`Fetching units for property ${propertyId} from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers })
  return response.json()
}

async function getRateInfo (unitTypeId, checkInDate, checkOutDate, guests, discountCode) {
  const url = new URL(`${apiUri}/units/${unitTypeId}/rates`)
  url.search = prepareParamsForSearch(checkInDate, checkOutDate, guests, discountCode)

  console.info(`Fetching rate info from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers })
  return response.json()
}

async function getRestrictionsInfo (unitTypeId, checkInDate, checkOutDate, guests) {
  const url = new URL(`${apiUri}/units/${unitTypeId}/restrictions`)
  url.search = prepareParamsForSearch(checkInDate, checkOutDate, guests)

  console.info(`Fetching restrictions from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers })
  return response.json()
}

async function getBlockedDates (unitTypeId) {
  const url = new URL(`${apiUri}/units/${unitTypeId}/unavailability`)

  console.info(`Fetching blocked dates from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers })
  return response.json()
}

async function getAvailabilityDateClassNames (unitTypeId) {
  const url = new URL(`${apiUri}/units/${unitTypeId}/availability_date_class_names`)

  console.info(`Fetching availability date class names from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers })
  return response.json()
}

async function getCompanyPage (copyKey) {
  const url = new URL(`${apiUri}/company_page?copyKey=${copyKey}`)

  console.info(`Fetching company page from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers })
  return response.json()
}

async function getAcceptance () {
  const url = new URL(`${janiisApiUri}/meta`)

  console.info(`Fetching acceptance info from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers: { Accepts: 'application/json' } })
  return response.json()
}

async function submitReservation (reservation) {
  const url = new URL(`${janiisApiUri}/pmcs/${reservation.reservation.pmcId}/websites/${reservation.reservation.websiteId}/new`)

  console.info(`Posting reservation to ${url}`)

  const response = await window.fetch(url.toString(), { method: 'POST', headers: { 'Content-Type': 'application/json', Accepts: 'application/json' }, body: JSON.stringify(reservation) })
  return response.json()
}

async function getPaymentSettings (unitTypeId) {
  const url = new URL(`${apiUri}/units/${unitTypeId}/payment_settings`)

  console.info(`Fetching payment settings from ${url}`)

  const response = await window.fetch(url.toString(), { method: 'GET', headers })
  return response.json()
}

export {
  getSearchResults,
  getUnitsForProperty,
  getRestrictionsInfo,
  getRateInfo,
  getBlockedDates,
  getAvailabilityDateClassNames,
  getCompanyPage,
  getAcceptance,
  submitReservation,
  getPaymentSettings
}
