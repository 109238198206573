/* globals AUTHORIZE_NET_JS */
import imgix from './imgix'

const authorizeNetJS = AUTHORIZE_NET_JS

const heroImgData = require('../../src/generated/imagery')
const heroImgSources = heroImgData.map(file => {
  const src = `https://www.reservationsunlimited.com/assets/heros/${file}`
  return imgix.buildSrc(src, { w: 1000, h: 573, fit: 'crop' })
})

function defaultStartDate () {
  const startDate = new Date()
  startDate.setHours(0, 0, 0, 0)
  return startDate
}

function defaultEndDate (startDate) {
  if (!startDate) startDate = defaultStartDate()
  let endDate = new Date(startDate)
  endDate.setHours(0, 0, 0, 0)
  endDate = endDate.setDate(endDate.getDate() + 2)
  return endDate
}

function dateFromQueryStringValue (dateQueryStringValue) {
  if (!dateQueryStringValue) return null
  const [year, month, day] = dateQueryStringValue.split('-')
  const date = new Date(2000, 0, 1, 0, 0, 0, 0)
  date.setDate(day)
  date.setMonth(month - 1)
  date.setYear(year)
  return date
}

function formatDateForSearch (date) {
  // toISOString() -> YYYY-MM-DDTHH:mm:ss.sssZ
  if (!date || !date.toISOString) return ''
  return date.toISOString().substr(0, 10)
}

function fuzzyPrice (amount) {
  if (typeof amount === 'number') {
    if (amount >= 500) { return 4 }
    if (amount >= 350) { return 3 }
    if (amount >= 200) { return 2 }
    if (amount > 0) { return 1 }
  }
}

function injectSvgSprite (path) {
  if (typeof window === 'undefined') return
  //   https://css-tricks.com/ajaxing-svg-sprite/
  const ajax = new XMLHttpRequest()
  ajax.open('GET', path, true)
  ajax.send()
  ajax.onload = function (e) {
    const div = document.createElement('div')
    div.className = 'd-none'
    div.innerHTML = ajax.responseText
    document.body.appendChild(div)
  }
}

function addAuthorizeNetScript () {
  const script = document.createElement('script')
  script.src = authorizeNetJS
  document.head.appendChild(script)
}

function removeAuthorizeNetScript () {
  const head = document.head
  const scripts = head.getElementsByTagName('script')

  Array.from(scripts)
    .filter(script => script.src === authorizeNetJS)
    .forEach(injectedScript => head.removeChild(injectedScript))
}

function randomizeArray (arr) {
  const newArr = []
  while (arr.length) {
    const index = Math.floor(Math.random() * arr.length)
    newArr.push(arr[index])
    arr.splice(index, 1)
  }
  return newArr
}

export {
  heroImgSources,
  defaultStartDate,
  defaultEndDate,
  dateFromQueryStringValue,
  formatDateForSearch,
  fuzzyPrice,
  injectSvgSprite,
  addAuthorizeNetScript,
  removeAuthorizeNetScript,
  randomizeArray
}
