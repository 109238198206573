import './style/index.scss'
import { h } from 'preact'
import { Provider as PrerenderProvider } from '@preact/prerender-data-provider'
import App from './components/app'

import 'lazysizes'
import 'bootstrap/js/src/collapse'

export default (props) => {
  return (
    <PrerenderProvider value={props}>
      <App />
    </PrerenderProvider>
  )
}
