import { h, Component } from 'preact'
import { Router } from 'preact-router'

import { injectSvgSprite } from '../lib/utils'

import AppBanner from './app_banner'
import Header from './header'
import Footer from './footer'

// Code-splitting is automated for routes
import Home from '../routes/home'
import CompanyPage from '../routes/company_page'
import Events from '../routes/events'
import Search from '../routes/search'
import Unit from '../routes/unit'
import Booking from '../routes/booking'
import Property from '../routes/property'
import Collection from '../routes/collection'
import Collections from '../routes/collections'
import Error404 from '../routes/404'

class App extends Component {
  componentDidMount () {
    injectSvgSprite('/assets/orion-svg-sprite.svg')
  }

  handleRouteChange (event) {
    if (typeof window !== 'undefined') {
      const previousURL = new URL(event.previous, window.location.href)
      const currentURL = new URL(event.url, window.location.href)
      if (previousURL.pathname !== currentURL.pathname) window.scrollTo(0, 0)
    }
  }

  render (props) {
    const gtmSnippet = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WS8H5D5');`

    return (
      <div id='app'>
        <script dangerouslySetInnerHTML={{ __html: gtmSnippet }} />
        <noscript><iframe src='https://www.googletagmanager.com/ns.html?id=GTM-WS8H5D5' height='0' width='0' style='display:none;visibility:hidden' /></noscript>
        <a class='sr-only sr-only-focusable' href='#skip-to-main-content'>Skip to main content</a>
        <AppBanner />
        <Header />
        <main id='skip-to-main-content'>
          <Router onChange={this.handleRouteChange}>
            <Home path='/' {...this.props} />
            <CompanyPage path='/about-us' {...this.props} />
            <CompanyPage path='/terms' {...this.props} />
            <CompanyPage path='/policies' {...this.props} />
            <Events path='/events' {...this.props} />
            <Search path='/search' {...this.props} />
            <Unit path='/units/:id' {...this.props} />
            <Booking path='/units/:id/reserve' {...this.props} />
            <Property path='/properties/:id' {...this.props} />
            <Collections path='/collections' {...this.props} />
            <Collection path='/collections/:slug' {...this.props} />
            <Error404 path='/404' {...this.props} default />
          </Router>
        </main>
        <Footer />
      </div>
    )
  }
}

export default App
