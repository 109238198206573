import { h, Component, createRef } from 'preact'
import { route } from 'preact-router'
import Select from 'react-select'

import { getSearchResults } from '../lib/api'

class JumpToProperty extends Component {
  state = {
    loading: false,
    error: false,
    options: []
  }

  ref = createRef()

  componentDidMount () {
    this.fetchResults()
  }

  async fetchResults () {
    this.setState({
      loading: true
    })

    try {
      const searchResults = await getSearchResults()
      const options = searchResults.map(result => {
        return { value: result.url, label: result.name }
      })

      options.sort((a, b) => {
        const labelA = a.label.toUpperCase()
        const labelB = b.label.toUpperCase()

        if (labelA < labelB) return -1
        if (labelA > labelB) return 1
        return 0
      })

      this.setState({
        loading: false,
        options
      })
    } catch (err) {
      this.setState({
        loading: false,
        error: true
      })
    }
  }

  handleSelection = (event) => {
    if (event && event.value) {
      setTimeout(() => {
        this.ref.current.select.clearValue()
      }, 1)
      route(event.value)
    }
  }

  renderLoaded () {
    return (
      <div class='jump-to-property'>
        <label for='jumpToProperty'>Jump To Property:</label>
        <Select id='jumpToProperty' options={this.state.options} onChange={this.handleSelection} />
      </div>
    )
  }

  render (_, { loading, error, options }) {
    return (
      <div class='jump-to-property mb-4'>
        <Select ref={this.ref} id='jumpToProperty' placeholder='Jump To Property' isLoading={loading} options={this.state.options} onChange={this.handleSelection} />
      </div>
    )
  }
}

export default JumpToProperty
