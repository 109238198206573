// import { h } from 'preact'

const AppBanner = () => (
  null
  // <header class='notice'>
  //   <p>
  //     Red River Ski &amp; Summer Area has agreed to adhere to COVID-19 safety practices, which means limited availability and blackout dates requiring reservations.  <a href='https://www.redriverskiarea.com/winter/20-21-winter-season/' rel='noopener noreferrer' target='_blank'>Read&nbsp;more.</a>
  //   </p>
  // </header>
)

export default AppBanner
